export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("apollo:error", (error) => {
    console.error(error);

    if (process.client) {
      const tokenCookie = useCookie("apollo:bcn.token");
      tokenCookie.value = null;
    }

    refreshNuxtData();
  });
});

