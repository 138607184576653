import { default as energy_45green_45practicesOKvtLbFOl6Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyIhX2Dau4AmMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderpBFnI8cGCUMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexWIWEIKvaoMMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamszVrYjOwp3Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lendervv1PG9OSn8Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesZsFBZJwfWWMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93fQyAGjLnrRMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexJx4r0EOZ82Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexaDzeU4bEAPMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93yV7CUyZxSDMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexXR8uIzpxQYMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offerFa3wBdwNyGMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionh1NwfH6opCMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesVqwsEIFfmkMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqry1t4AQqT1Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resources8P7KhR4lPIMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexmmwB51He5bMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations80XYVsGhz4Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersBq2ENdbR82Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexLnRkoBKtj4Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexS5Vf9q8KEKMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexYfQBbByhY0Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexsJHTOwsZTtMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexY9BmXJnW9bMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexEZFQ7OFYa1Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexrDxrLzEMNGMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationJd4Ofr4OEyMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionhaTllt1LchMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeL1zMMK5qI6Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceNBTuUxB2UpMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93KfjPjT9zqzMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexyE6aNNDQ5GMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexO0xH07KjQyMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnesseZuVrY0eORMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93GmIPEyQCuOMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesyVE4SGNVIrMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchkbO0Ls8SsUMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhousesN2dbejGRyMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionosdJABa9O1Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottagezn2ifkC3HvMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexczXj8IaSFDMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsXSl2NreNSsMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usWOvSjFDWrsMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacyterms0eDkR4kmMqMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxji7Xxtb4hFMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93HH6D7SR3JaMeta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stub5yRHBAmWd8Meta } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub5yRHBAmWd8 } from "/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesOKvtLbFOl6Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesOKvtLbFOl6Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesOKvtLbFOl6Meta || {},
    alias: energy_45green_45practicesOKvtLbFOl6Meta?.alias || [],
    redirect: energy_45green_45practicesOKvtLbFOl6Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyIhX2Dau4AmMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyIhX2Dau4AmMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyIhX2Dau4AmMeta || {},
    alias: fun_45part_45journeyIhX2Dau4AmMeta?.alias || [],
    redirect: fun_45part_45journeyIhX2Dau4AmMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderpBFnI8cGCUMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderpBFnI8cGCUMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderpBFnI8cGCUMeta || {},
    alias: homesafe_45colorado_45master_45builderpBFnI8cGCUMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderpBFnI8cGCUMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexWIWEIKvaoMMeta?.name ?? "about-us",
    path: indexWIWEIKvaoMMeta?.path ?? "/about-us",
    meta: indexWIWEIKvaoMMeta || {},
    alias: indexWIWEIKvaoMMeta?.alias || [],
    redirect: indexWIWEIKvaoMMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamszVrYjOwp3Meta?.name ?? "about-us-our-team",
    path: our_45teamszVrYjOwp3Meta?.path ?? "/about-us/our-team",
    meta: our_45teamszVrYjOwp3Meta || {},
    alias: our_45teamszVrYjOwp3Meta?.alias || [],
    redirect: our_45teamszVrYjOwp3Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lendervv1PG9OSn8Meta?.name ?? "about-us-preferred-lender",
    path: preferred_45lendervv1PG9OSn8Meta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lendervv1PG9OSn8Meta || {},
    alias: preferred_45lendervv1PG9OSn8Meta?.alias || [],
    redirect: preferred_45lendervv1PG9OSn8Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesZsFBZJwfWWMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesZsFBZJwfWWMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesZsFBZJwfWWMeta || {},
    alias: realtor_45resourcesZsFBZJwfWWMeta?.alias || [],
    redirect: realtor_45resourcesZsFBZJwfWWMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93fQyAGjLnrRMeta?.name ?? "blog-post",
    path: _91post_93fQyAGjLnrRMeta?.path ?? "/blog/:post()",
    meta: _91post_93fQyAGjLnrRMeta || {},
    alias: _91post_93fQyAGjLnrRMeta?.alias || [],
    redirect: _91post_93fQyAGjLnrRMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexJx4r0EOZ82Meta?.name ?? "blog-category-category",
    path: indexJx4r0EOZ82Meta?.path ?? "/blog/category/:category()",
    meta: indexJx4r0EOZ82Meta || {},
    alias: indexJx4r0EOZ82Meta?.alias || [],
    redirect: indexJx4r0EOZ82Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaDzeU4bEAPMeta?.name ?? "blog",
    path: indexaDzeU4bEAPMeta?.path ?? "/blog",
    meta: indexaDzeU4bEAPMeta || {},
    alias: indexaDzeU4bEAPMeta?.alias || [],
    redirect: indexaDzeU4bEAPMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93yV7CUyZxSDMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93yV7CUyZxSDMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93yV7CUyZxSDMeta || {},
    alias: _91_46_46_46slug_93yV7CUyZxSDMeta?.alias || [],
    redirect: _91_46_46_46slug_93yV7CUyZxSDMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexXR8uIzpxQYMeta?.name ?? "campaigns",
    path: indexXR8uIzpxQYMeta?.path ?? "/campaigns",
    meta: indexXR8uIzpxQYMeta || {},
    alias: indexXR8uIzpxQYMeta?.alias || [],
    redirect: indexXR8uIzpxQYMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offerFa3wBdwNyGMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offerFa3wBdwNyGMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offerFa3wBdwNyGMeta || {},
    alias: central_45park_45special_45offerFa3wBdwNyGMeta?.alias || [],
    redirect: central_45park_45special_45offerFa3wBdwNyGMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionh1NwfH6opCMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionh1NwfH6opCMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionh1NwfH6opCMeta || {},
    alias: ask_45a_45questionh1NwfH6opCMeta?.alias || [],
    redirect: ask_45a_45questionh1NwfH6opCMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesVqwsEIFfmkMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesVqwsEIFfmkMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesVqwsEIFfmkMeta || {},
    alias: career_45opportunitiesVqwsEIFfmkMeta?.alias || [],
    redirect: career_45opportunitiesVqwsEIFfmkMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqry1t4AQqT1Meta?.name ?? "contact-us-faq",
    path: faqry1t4AQqT1Meta?.path ?? "/contact-us/faq",
    meta: faqry1t4AQqT1Meta || {},
    alias: faqry1t4AQqT1Meta?.alias || [],
    redirect: faqry1t4AQqT1Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resources8P7KhR4lPIMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resources8P7KhR4lPIMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resources8P7KhR4lPIMeta || {},
    alias: homeowner_45resources8P7KhR4lPIMeta?.alias || [],
    redirect: homeowner_45resources8P7KhR4lPIMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexmmwB51He5bMeta?.name ?? "contact-us",
    path: indexmmwB51He5bMeta?.path ?? "/contact-us",
    meta: indexmmwB51He5bMeta || {},
    alias: indexmmwB51He5bMeta?.alias || [],
    redirect: indexmmwB51He5bMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locations80XYVsGhz4Meta?.name ?? "contact-us-our-locations",
    path: our_45locations80XYVsGhz4Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locations80XYVsGhz4Meta || {},
    alias: our_45locations80XYVsGhz4Meta?.alias || [],
    redirect: our_45locations80XYVsGhz4Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersBq2ENdbR82Meta?.name ?? "current-offers",
    path: current_45offersBq2ENdbR82Meta?.path ?? "/current-offers",
    meta: current_45offersBq2ENdbR82Meta || {},
    alias: current_45offersBq2ENdbR82Meta?.alias || [],
    redirect: current_45offersBq2ENdbR82Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexLnRkoBKtj4Meta?.name ?? "events-event",
    path: indexLnRkoBKtj4Meta?.path ?? "/events/:event()",
    meta: indexLnRkoBKtj4Meta || {},
    alias: indexLnRkoBKtj4Meta?.alias || [],
    redirect: indexLnRkoBKtj4Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexS5Vf9q8KEKMeta?.name ?? "events",
    path: indexS5Vf9q8KEKMeta?.path ?? "/events",
    meta: indexS5Vf9q8KEKMeta || {},
    alias: indexS5Vf9q8KEKMeta?.alias || [],
    redirect: indexS5Vf9q8KEKMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexYfQBbByhY0Meta?.name ?? "homes-location-community",
    path: indexYfQBbByhY0Meta?.path ?? "/homes/:location()/:community()",
    meta: indexYfQBbByhY0Meta || {},
    alias: indexYfQBbByhY0Meta?.alias || [],
    redirect: indexYfQBbByhY0Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsJHTOwsZTtMeta?.name ?? "homes-location-community-models-model",
    path: indexsJHTOwsZTtMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexsJHTOwsZTtMeta || {},
    alias: indexsJHTOwsZTtMeta?.alias || [],
    redirect: indexsJHTOwsZTtMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY9BmXJnW9bMeta?.name ?? "homes-location-community-plans-plan",
    path: indexY9BmXJnW9bMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexY9BmXJnW9bMeta || {},
    alias: indexY9BmXJnW9bMeta?.alias || [],
    redirect: indexY9BmXJnW9bMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZFQ7OFYa1Meta?.name ?? "homes-location-community-qmi-home",
    path: indexEZFQ7OFYa1Meta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexEZFQ7OFYa1Meta || {},
    alias: indexEZFQ7OFYa1Meta?.alias || [],
    redirect: indexEZFQ7OFYa1Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrDxrLzEMNGMeta?.name ?? "index",
    path: indexrDxrLzEMNGMeta?.path ?? "/",
    meta: indexrDxrLzEMNGMeta || {},
    alias: indexrDxrLzEMNGMeta?.alias || [],
    redirect: indexrDxrLzEMNGMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationJd4Ofr4OEyMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationJd4Ofr4OEyMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationJd4Ofr4OEyMeta || {},
    alias: knolls_45hoa_45informationJd4Ofr4OEyMeta?.alias || [],
    redirect: knolls_45hoa_45informationJd4Ofr4OEyMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionhaTllt1LchMeta?.name ?? "land-acquisition",
    path: land_45acquisitionhaTllt1LchMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionhaTllt1LchMeta || {},
    alias: land_45acquisitionhaTllt1LchMeta?.alias || [],
    redirect: land_45acquisitionhaTllt1LchMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeL1zMMK5qI6Meta?.name ?? "lifestyle-brents-place",
    path: brents_45placeL1zMMK5qI6Meta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeL1zMMK5qI6Meta || {},
    alias: brents_45placeL1zMMK5qI6Meta?.alias || [],
    redirect: brents_45placeL1zMMK5qI6Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceNBTuUxB2UpMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceNBTuUxB2UpMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceNBTuUxB2UpMeta || {},
    alias: experience_45lower_45maintenanceNBTuUxB2UpMeta?.alias || [],
    redirect: experience_45lower_45maintenanceNBTuUxB2UpMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93KfjPjT9zqzMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93KfjPjT9zqzMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93KfjPjT9zqzMeta || {},
    alias: _91testimonial_93KfjPjT9zqzMeta?.alias || [],
    redirect: _91testimonial_93KfjPjT9zqzMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexyE6aNNDQ5GMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexyE6aNNDQ5GMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexyE6aNNDQ5GMeta || {},
    alias: indexyE6aNNDQ5GMeta?.alias || [],
    redirect: indexyE6aNNDQ5GMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexO0xH07KjQyMeta?.name ?? "lifestyle-reviews",
    path: indexO0xH07KjQyMeta?.path ?? "/lifestyle/reviews",
    meta: indexO0xH07KjQyMeta || {},
    alias: indexO0xH07KjQyMeta?.alias || [],
    redirect: indexO0xH07KjQyMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnesseZuVrY0eORMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnesseZuVrY0eORMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnesseZuVrY0eORMeta || {},
    alias: whats_45lifefullnesseZuVrY0eORMeta?.alias || [],
    redirect: whats_45lifefullnesseZuVrY0eORMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93GmIPEyQCuOMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93GmIPEyQCuOMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93GmIPEyQCuOMeta || {},
    alias: _91_46_46_46slug_93GmIPEyQCuOMeta?.alias || [],
    redirect: _91_46_46_46slug_93GmIPEyQCuOMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesyVE4SGNVIrMeta?.name ?? "my-favorites",
    path: my_45favoritesyVE4SGNVIrMeta?.path ?? "/my-favorites",
    meta: my_45favoritesyVE4SGNVIrMeta || {},
    alias: my_45favoritesyVE4SGNVIrMeta?.alias || [],
    redirect: my_45favoritesyVE4SGNVIrMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchkbO0Ls8SsUMeta?.name ?? "new-home-search",
    path: new_45home_45searchkbO0Ls8SsUMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchkbO0Ls8SsUMeta || {},
    alias: new_45home_45searchkbO0Ls8SsUMeta?.alias || [],
    redirect: new_45home_45searchkbO0Ls8SsUMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhousesN2dbejGRyMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousesN2dbejGRyMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousesN2dbejGRyMeta || {},
    alias: easyhousesN2dbejGRyMeta?.alias || [],
    redirect: easyhousesN2dbejGRyMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionosdJABa9O1Meta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionosdJABa9O1Meta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionosdJABa9O1Meta || {},
    alias: limitededitionosdJABa9O1Meta?.alias || [],
    redirect: limitededitionosdJABa9O1Meta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottagezn2ifkC3HvMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottagezn2ifkC3HvMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottagezn2ifkC3HvMeta || {},
    alias: wee_45cottagezn2ifkC3HvMeta?.alias || [],
    redirect: wee_45cottagezn2ifkC3HvMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexczXj8IaSFDMeta?.name ?? "our-homes",
    path: indexczXj8IaSFDMeta?.path ?? "/our-homes",
    meta: indexczXj8IaSFDMeta || {},
    alias: indexczXj8IaSFDMeta?.alias || [],
    redirect: indexczXj8IaSFDMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsXSl2NreNSsMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsXSl2NreNSsMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsXSl2NreNSsMeta || {},
    alias: our_45neighborhoodsXSl2NreNSsMeta?.alias || [],
    redirect: our_45neighborhoodsXSl2NreNSsMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usWOvSjFDWrsMeta?.name ?? "partner-with-us",
    path: partner_45with_45usWOvSjFDWrsMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usWOvSjFDWrsMeta || {},
    alias: partner_45with_45usWOvSjFDWrsMeta?.alias || [],
    redirect: partner_45with_45usWOvSjFDWrsMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacyterms0eDkR4kmMqMeta?.name ?? "privacyterms",
    path: privacyterms0eDkR4kmMqMeta?.path ?? "/privacyterms",
    meta: privacyterms0eDkR4kmMqMeta || {},
    alias: privacyterms0eDkR4kmMqMeta?.alias || [],
    redirect: privacyterms0eDkR4kmMqMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxji7Xxtb4hFMeta?.name ?? "sandbox",
    path: sandboxji7Xxtb4hFMeta?.path ?? "/sandbox",
    meta: sandboxji7Xxtb4hFMeta || {},
    alias: sandboxji7Xxtb4hFMeta?.alias || [],
    redirect: sandboxji7Xxtb4hFMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HH6D7SR3JaMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93HH6D7SR3JaMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93HH6D7SR3JaMeta || {},
    alias: _91_46_46_46slug_93HH6D7SR3JaMeta?.alias || [],
    redirect: _91_46_46_46slug_93HH6D7SR3JaMeta?.redirect,
    component: () => import("/codebuild/output/src670347131/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/about-us/in-the-news/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/about-us/realtor-program/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/about-us/what-we-do/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/about-us/who-we-are/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/build-with-us/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/career-opportunities/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/email-signup/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/get-updates/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/get-updates/thank-you/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/how-to-video-library/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/lifestyle/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/locations/silver-leaf/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/login/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/lost-password/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/maintenance-items/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/organism-page/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/home-collections/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/register/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/reset-password/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/style-guide/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  },
  {
    name: component_45stub5yRHBAmWd8Meta?.name ?? undefined,
    path: component_45stub5yRHBAmWd8Meta?.path ?? "/warranty-service-request/",
    meta: component_45stub5yRHBAmWd8Meta || {},
    alias: component_45stub5yRHBAmWd8Meta?.alias || [],
    redirect: component_45stub5yRHBAmWd8Meta?.redirect,
    component: component_45stub5yRHBAmWd8
  }
]