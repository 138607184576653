import gql from "graphql-tag";

export const GET_META = gql`
  query GetMeta($uri: ID!) {
    page(id: $uri, idType: URI) {
      seoMeta {
        seoTitle
        seoMetaDescription
      }
    }
  }
`;
