<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { createApp, provide, h, computed } from "vue";
import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";
import { GET_META } from "~/graphql/GetMeta.js";

const config = useRuntimeConfig();
const graphqlUrl = config.public.GRAPHQL_URL;

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: graphqlUrl,
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Page_Hlorganisms_hlOrganism: {
      // ToDo: Implement automatic unique ID ACF field for organisms
      // Since there is no obvious unique id field, and the id field
      // returns null, the Apollo cache will repeat the first organism
      // over and over because it can't tell the difference.
      // Here we define keyFields for the cache, hoping that every
      // organism has a unique combination of the following.
      // This should work for now, but maybe either revisit this
      // policy, or define a truly unique id field to use instead.
      //
      // Somebody made a plugin for this, but it hasn't been updated in
      // 8 years. One possible approach though:
      // https://github.com/KLicheR/wp-acf-unique_id
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "item",
      ],
    },
    Page_Hlorganisms_hlOrganism_item: {
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "image",
        "imageLink",
      ],
    },
    Campaign_Hlorganisms_hlOrganism_item: {
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "image",
        "imageLink",
      ],
    },
    Neighborhood_Neighborhood_alternatingContent: {
      keyFields: ["id", "title", "link", "description"],
    },
    User: {
      keyFields: ["id", "databaseId", "favorites"],
    },
    Neighborhood_Neighborhood: {
      merge: true,
    },
    Page: {
      merge: true,
    },
    Plan_Floorplandetails: {
      merge: true,
      keyFields: ["displayTitle"],
    },
    Event: {
      keyFields: ["id", "databaseId", "uri"],
    },
  },
});

// Middleware for adding the Authorization token to headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const cookie = useCookie("apollo:bcn.token");
  const token = cookie.value;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  }));

  return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
    query: {
      fetchPolicy: "cache-first",
    },
  },
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

provideApolloClient(apolloClient);

const route = useRoute();
const { result, loading, refetch } = useQuery(GET_META, { uri: route.path });

// Setup default meta
const meta = reactive({
  title: "Boulder Creek Neighborhoods",
  description:
    "Boulder Creek Neighborhoods: Locally Owned, Lower-Maintenance Home Builder",
});

onBeforeMount(async () => {
  watch([result, loading], () => {
    if (!loading.value) {
      const data = result.value ?? [];

      meta.title = data?.page?.seoMeta?.seoTitle ?? meta.title;
      meta.description =
        data?.page?.seoMeta?.seoMetaDescription ?? meta.description;
      const ogImage = "https://livebouldercreek.com/img/bcn-logo-horiz-r.jpg";

      useHead({
        title: meta.title + " - Boulder Creek Neighborhoods",
        meta: [
          { name: "description", content: meta.description },
          {
            property: "og:title",
            content: meta.title
              ? meta.title + " - Boulder Creek Neighborhoods"
              : "Boulder Creek Neighborhoods",
          },
          {
            property: "og:description",
            content: meta.description
              ? meta.description
              : "Boulder Creek Neighborhoods: Locally Owned, Lower-Maintenance Home Builder",
          },
          {
            property: "og:image",
            content: ogImage,
          },
          {
            property: "og:type",
            content: "website",
          },
        ],
      });
    }
  });
});

watch(
  () => route.path,
  async (newPath) => {
    // Re-fetch meta data on route change
    await refetch({ uri: newPath });

    if (result.value) {
      const data = result.value;
      meta.title = data?.page?.seoMeta?.seoTitle ?? meta.title;
      meta.description =
        data?.page?.seoMeta?.seoMetaDescription ?? meta.description;
      const ogImage = "https://livebouldercreek.com/img/bcn-logo-horiz-r.jpg";

      useHead({
        title: meta.title + " - Boulder Creek Neighborhoods",
        meta: [
          { name: "description", content: meta.description },
          {
            property: "og:title",
            content: meta.title
              ? meta.title + " - Boulder Creek Neighborhoods"
              : "Boulder Creek Neighborhoods",
          },
          {
            property: "og:description",
            content: meta.description
              ? meta.description
              : "Boulder Creek Neighborhoods: Locally Owned, Lower-Maintenance Home Builder",
          },
          {
            property: "og:image",
            content: ogImage,
          },
          {
            property: "og:type",
            content: "website",
          },
        ],
      });
    }
  },
  { immediate: true },
);

useHead({
  title: meta.title,
  meta: [{ name: "description", content: meta.description }],
  htmlAttrs: {
    class: "scroll-smooth",
  },
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/img/favicon.ico",
    },
  ],
  script: [
    {
      type: "text/javascript",
      innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PJ2MH9N');`,
    },
    {
      type: "text/javascript",
      src: "/js/liveperson.js",
      tagPosition: "bodyClose",
      defer: true,
    },
    {
      type: "text/javascript",
      innerHTML: 'var rl_siteid = "a11cbb79-00af-4a1a-855a-771ac2b55312";',
      tagPosition: "bodyClose",
    },
    {
      type: "text/javascript",
      src: "//cdn.rlets.com/capture_static/mms/mms.js",
      async: "true",
      tagPosition: "bodyClose",
    },
  ],
  noscript: [
    {
      children:
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJ2MH9N" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
      body: "bodyOpen",
    },
  ],
});
</script>
